import React from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import { graphql, Link } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import ColumnLayout from "../components/layout/ColumnLayout"
import { PhoneIcon } from "@heroicons/react/solid"
import { MailIcon } from "@heroicons/react/outline"
import ContactForm from "../components/contact/ContactForm"
import ArticleBox from "../components/solution/ArticleBox"
import ArchiveTemplate from "../components/landing/ArchiveTemplate"

const UseCasesPage = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <ArchiveTemplate
      articleKey="usecase-"
      description={t("UseCases-Description")}
      headline={t("UseCases-Headline")}
      pageTitle={t("UseCases-Page-Title")}
    />
  )
}

export default UseCasesPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
